
import styled from 'styled-components';

export const MasonryComponents = styled.div`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 10px;
  }

`;

export const WrapLoadButton = styled.div`
  display: flex;
  justify-content: center;
  .ant-btn-primary{
    color: white!important;
    background: black!important;
    border-color: black!important;
    padding:3px 11px 4px 11px;
    &:hover {
      cursor:pointer;
    }
  }
`

export const WrapLearnMore = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width:100%;
  @media only screen and (max-width: 820px) {
  flex-direction: column;  justify-content: space-between;
  }
`

export const LearnMore = styled.a`
  color: black;
  margin-top: 20px;
  display: flex;
  border-style: solid;
  width:48%;
  height:110px;
  @media only screen and (max-width: 820px) {
    width:100%;
  }
`

export const LearnMoreText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 30px;
  font-weight: bold;
  padding:10px;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 322px) {
    padding: 8px;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: flex-start;
  padding:15px 10px;
  height:100%;
  filter: brightness(100);
  background-color: black;
  
`
export const LearnMoreButton = styled.span`
  font-size:18px;
  font-weight: bold;
  color:black;
`;

export const Button = styled.div`
  display:flex;
  font-size:16px;
  font-weight: 500;
  cursor:pointer;
  img{
    padding-right: 5px;
  }
`;

