import {useState} from "react";
import {useHistory} from 'react-router-dom';
import useFetchHook from 'hooks/useFetchHook';
import CardsStory from 'components/Cards/stories'
import Masonry from 'react-masonry-css'
import {
  MasonryComponents,
  WrapLearnMore,
  LearnMore,
  Icon,
  LearnMoreText,
  LearnMoreButton,
  Button,
  WrapLoadButton
} from './_styles'
import AboutIcon from 'assets/img/about.svg'
import ContributorsIcon from 'assets/img/contributors.svg'
import Arrow from "../../assets/img/arrow-right-up-line.svg";

const StoriesComponents = () => {
  const history = useHistory();
  const url = '/wp/v2/posts?lxl_per_page=100'
  const [result, error, isLoading] = useFetchHook(url);
  const inititalLimit = 6;
  const [limit, setLimit] = useState(inititalLimit);

  const loadMore = () => {
    setLimit(limit + 6);
  };

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };


  if (isLoading) return <h2>Loading...</h2>

  return (

    <MasonryComponents>
      <h1>Stories.</h1>
      {error && <p>Error: {error}</p>}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {result &&
        result.slice(0, limit).map(post =>
          <div key={post.id}
               onClick={() =>
                 history.push(`/story/${post.slug}`, {from: `${post.slug}`})
               }>
            <>
              <CardsStory
                id={post.id}
                title={post.title.rendered}
                quote={post.acf.quote}
                author={post.acf.author_by}
                image={post.acf.image_display.url}
              />
            </>
          </div>
        )
        }
      </Masonry>
      
      <WrapLoadButton>
        {result && !(limit >= result.length) && <Button type="primary" className="ant-btn-primary" onClick={loadMore}>Load more</Button>}
      </WrapLoadButton>

      <WrapLearnMore>
        <LearnMore href="/about-us">
          <Icon><img src={AboutIcon} alt=""/></Icon>
          <LearnMoreText>About the campaign<LearnMoreButton>Learn more<img src={Arrow} alt="arrow"/></LearnMoreButton></LearnMoreText>
        </LearnMore>
        <LearnMore href="/contributors">
          <Icon><img src={ContributorsIcon} alt=""/></Icon>
          <LearnMoreText>Contributors<LearnMoreButton>Learn more<img src={Arrow} alt="arrow"/></LearnMoreButton></LearnMoreText>
        </LearnMore>
      </WrapLearnMore>
    </MasonryComponents>

  )
}

export default StoriesComponents;
