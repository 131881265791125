import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LayoutComponent from './components/Layout';
import ScrollComponent from './components/Slider';
import AboutUs from './pages/AboutUs';
import StoriesComponents from 'pages/Stories'
import ContributorComponents from 'pages/Contributors';
import DownloadsComponents from 'pages/Downloads'
import IndividualStoriesComponents from 'pages/IndividualStory'
import NotFound from 'pages/error404';
import 'antd/dist/antd.css';
import { GlobalStyle } from './_styles';


function StoriesPage() {
  return <LayoutComponent><StoriesComponents /></LayoutComponent>;
}

function IndividualStoriesPage() {
  return <LayoutComponent><IndividualStoriesComponents /></LayoutComponent>;
}

function ContributorsPage() {
  return <LayoutComponent><ContributorComponents /></LayoutComponent>;
}

function DownloadsPage() {
  return <LayoutComponent><DownloadsComponents /></LayoutComponent>;
}

function AboutPage() {
  return <LayoutComponent><AboutUs /></LayoutComponent>;
}

function LandingPage() {
  return <ScrollComponent />;
}

const MySite = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/stories" exact component={StoriesPage} />
        <Route path="/contributors" exact component={ContributorsPage} />
        <Route path="/about-us" exact component={AboutPage} />
        <Route path="/resources" exact component={DownloadsPage} />
        <Route path="/story/:slug" component={IndividualStoriesPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )
}

export default function AppRouter() {
  return (
    <Router>
      <GlobalStyle />
      <MySite />
    </Router>
  );
}
