import styled from 'styled-components';

export const ImageCard = styled.img`
  object-fit: cover;
  height: 100%;
  padding: 15px;
`;

export const MasonryComponents = styled.div`
  .ant-card {
    background: transparent;
  }

  .ant-card-bordered {
   border: 0px solid #f0f0f0;
  }

  .ant-card-hoverable:hover {
    box-shadow: 0 0 0 3px rgba(33,33,33,0.3);
  }

  .ant-card-body {
    padding: 24px 15px;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
    
  }
  .my-masonry-grid_column {
    padding-left: 0px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 30px;
  }



  .ant-card-meta-title {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-size: 32px;
    color: black;
    font-weight: bold;
    margin: 0 !important;
    white-space: pre-line;
    line-height:36px;
    @media only screen and (max-width: 1280px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .ant-card-meta-description {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    padding: 10px 0;
    font-size: 16px;
    color: rgba(33, 33, 33, 0.7);
  }

  .downloadCard{
    position:relative;
  }

  .tag{
    position: absolute;
    top: 30px;
    z-index: 10;
    left: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 5px 8px;
  }
`;

export const Filter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:20px 0 40px;
`

export const FilterButton = styled.button`
  border: 3px solid #BCBCBC;
  background-color: transparent;
  border-radius: 30px;
  margin-right:15px;
  padding: 8px 30px;
  font-weight:normal;
  color:black;
  margin-top:10px;
`

export const WrapLoadButton = styled.div`
  display: flex;
  justify-content: center;
`

export const Card = styled.div`
  height: 100%;
  background: transparent;
  border: none;
`;

export const Button = styled.div`
  display:flex;
  font-size:18px;
  font-weight: bold;
  cursor: pointer;
  a{
    font-size:18px;
    font-weight: bold;
  }
  
  img{
    padding-right: 5px;
    padding-bottom: 5px;
  }
`;

export const Meta = styled.div`
  height: 100%;
`;
