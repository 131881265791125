
import styled from 'styled-components';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #FFFBF4;
  width:100%;
  padding: 10px 0;
  max-width: 1200px;
  @media only screen and (min-width: 1280px) {
    padding:30px;
    z-index:2;
    position:fixed;
    top:0;
  }
`;

export const HeaderWrapLanding = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width:100%;
  height:100px;
  padding: 10px 0;
  @media only screen and (min-width: 767px) {
    width:auto;
    height:auto;
  }
  @media only screen and (min-width: 1280px) {
    padding:30px;
    width:88%;
    z-index:2;
    position:absolute;
    top:0;
  }
`;

