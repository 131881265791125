import { Link } from 'react-router-dom';
import { Logo, StickyLogo  } from './_styles'
import LogoImg from 'assets/img/logo.svg'

const LogoComponent = () => {

  return (
    <StickyLogo>
        <Link to="/" id="stickyLogo" className="wrap_stickyLogo sticky">
          <Logo src={LogoImg} alt="logo" />
        </Link>
    </StickyLogo>
  )
}

export default LogoComponent;
