import { withRouter } from 'react-router';
import HighlightsComponents from 'components/Highlights';
import PageInfo from './pageInfo';

const IndividualStoriesComponents = function IndividualStoriesComponents({ match }) {

  return (
    <div>
      <PageInfo />
      <HighlightsComponents postSlug={match.params.slug} />
    </div>
  )
}

export default withRouter(IndividualStoriesComponents);