import styled from 'styled-components';

export const StoryCard = styled.div`
  .ant-card {
    background: transparent;
    cursor:pointer;
  }

  .ant-card-bordered {
    border: none;
  }

  .ant-card-body {
    padding: 20px 0;
  }

  .ant-card-meta-title {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.4);
    margin: 0 !important;
    white-space: pre-line;
    @media only screen and (min-width: 1280px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  
  .ant-card-meta-description {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: rgba(33, 33, 33);
    margin: 0 !important;
    @media only screen and (min-width: 1280px) {
      font-size: 30px;
      line-height: 34px;
      letter-spacing:0px;
    }
  }
  
`;

export const ImageCard = styled.img`
  object-fit: cover;
  height: 100%;
`;

export const Button = styled.div`
  cursor: pointer;
  margin-top: 10px;
  font-size:18px;
  font-weight: bold;
`;


