import styled from 'styled-components';

export const ButtonWrap = styled.div`
  margin-top: 100px;
  cursor: pointer;
  @media only screen and (max-width: 1279px) {
    margin-top: 50px;
  }
`;

export const Button = styled.a`
  font-size:18px;
  font-weight: bold;
  img{
    transform: rotate(270deg);
  }
`;

export const HeroWrap = styled.div`
  padding: 30px 0;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  @media only screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;
export const HeroLeft = styled.div`
  background: #7C5756;
  display:flex;
  align-items: center;
  img{
    width:600px;
    height:600px;
    object-fit: cover;
    @media only screen and (max-width: 1250px) {
      width:100%;
      max-height: 400px;
      padding:30px;
    }
  }
  @media only screen and (max-width: 1250px) {
    width:100%
  }
`;

export const HeroRight = styled.div`
  padding:30px;
  display:flex;
  flex-direction: column;
  width:600px;
  height:600px;
  @media only screen and (max-width: 1250px) {
    width:100%;
    height:auto;
  }
  @media only screen and (max-width: 1280px) {
    width:100%;
  }
`;

export const AuthorImage = styled.img`
  width: 136px;
  height:136px;
`;

export const AuthorName = styled.div`
  padding-top: 30px;
  color: white;
  font-size: 32px;
  font-weight: bold;
`;

export const AuthorDescription = styled.div`
  padding-top: 30px;
  color: white;
  opacity: 70%;
  font-size: 16px;
`;

export const ShareWrap = styled.div`
  margin: 30px 0;
  padding-left: 10px;
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 40%;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-left: 3px solid white;
`;

export const SocialsWrap = styled.div`
  display:flex;
  justify-content: flex-start;
`;

export const SocialIcon = styled.a`
  img {
    filter: invert(100%);
    -webkit-filter: invert(100%);
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 5px;
  }
`;

export const Story = styled.div`
  width:100%;
  max-width:766px;
  margin:0 auto 30px auto;

  cite{
    
    font-size: 16px;
    opacity: 90%;
  };

  ul {
    list-style-type: square;
    padding-left:40px;
    margin-bottom: 10px;

    li{
      display: list-item;
      font-size: 20px;
      line-height: 24px;

      span {
        font-size: 16px;
        line-height: 24px;
        font-weight:500;
      }

    }

  }
  
  ol {

    li {
      font-size:14px;
      color:rgba(0,0,0,1);

      span {
        font-size:14px;
        color:rgba(0,0,0,1);
        opacity:1;
      }

      a {
        font-size:14px;
        color:rgba(0,0,0,1);
      }

    }

  }

  hr {
    margin:50px 0;
  }

  span{
    font-size: 16px;
    opacity: 1;
    line-height: 24px;
  }
  img{
    width:100%;
    padding: 20px 0;
    object-fit: cover;
    height: auto;
`;

