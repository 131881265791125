import { Link } from 'react-router-dom';
import { Logo, StickyLogo  } from './_styles'
import LogoImgWhite from 'assets/img/whitelogo.svg'
import LogoImgBlack from 'assets/img/logo.svg'

const LogoComponent = ({blackImg}) => {

  return (
    <StickyLogo>
        <Link to="/" id="stickyLogo" className="wrap_stickyLogo sticky stickyWhite">
          {blackImg ? <Logo src={LogoImgBlack} alt="logo" /> : <Logo src={LogoImgWhite} alt="logo" />}
        </Link>
    </StickyLogo>
  )
}

export default LogoComponent;
