
import styled from 'styled-components';
import Gif from "../../assets/img/health-for-all.gif";
import PhoneError from "../../assets/img/Phone.svg";

export const ScreenMessage = styled.div`
    position:relative;
    display:flex;
    display:-webkit-flex;
    flex-direction:column;
    align-content:justify-between;
    align-items:center;
    height:100%;
    width:100%;
    padding:0px 40px;

    .rotate-phone{
        width:260px;
        height:260px;
        background-image:url(${PhoneError});
    }

    h4{
        padding:0px 20px;
        font-size:30px;
        text-align:center;
        line-height:34px;
    }
    @media screen and (min-height:550px){
        display:none;
    }
`;

export const Landing = styled.div`
position:relative;
max-width:100%;

    @media screen and (max-height:550px){
        display:none;
        background-color:black;
    }

    @media screen and (min-width:768px){
        position:sticky;
        height:100%;
    }

    .viewport{
        width:100%;
        height:100%;
        position:relative;
    }
    
    .scroll{
        width:100%;
        height:auto;
        min-height: -webkit-fill-available;
        display:flex;
        flex-direction:column;
        @media screen and (min-width:700px){
            flex-direction:row;
            height:100%;
        }
    }
    .card{
        width: 100vw;
        min-width: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        @media screen and (min-width:768px){
            flex-direction:row;
        }

        &#firstSlide{
            min-width:100%;
            @media screen and (min-width:768px){
                display: inline-flex;
                flex-direction:row;
                min-width:200%;
                height:100%;
                min-height:100%;
            }
            @media screen and (min-width:1024px){
                min-width:100%;
                height:100vh;
            }
        }
        
        a{ 
            color:inherit;
            font-size:18px;
            font-weight:bold;
        }

        .watchVid { 
            color:#fff;
            font-size:18px;
            font-weight:bold;
            background:none;
            border:none;
            cursor:pointer;
            padding:20px 0;
        }

        .landing {
            display:flex;
            display:-webkit-flex;
            background-color:rgba(255, 251, 244, 1) !important;
            background-image:url(${Gif});
            background-size:cover;
            background-position:center;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            width:100%;
            z-index:0;
            height: auto
            justify-content: flex-start;
            align-content: center;
            padding-top:20px;
            @media screen and (min-width:768px){
                flex-direction: row;
                z-index:2;
                justify-content: space-between;
                padding-top:auto;
                height: 100vh;
                min-height: -webkit-fill-available;
            }
            @media screen and (min-width:1024px){
                height: 100vh;
                min-height:auto;
            }
            
            div ul a, nav a {
                font-size: 16px;
                font-weight:500;
                li {
                    list-style-type: none;
                    display: inline-flex;
                    color: #fff;
                }
            } 

            #stickyLogo {
                position:relative;
                height:auto;
                left:25px;
                top:0;
                @media screen and (min-width:768px){
                    position:absolute;
                    height:100vh;
                    left:25px;
                    top:20px;
                }
            }

            .stickyWhite {
                height:0 !important;

                &:before{
                    content: '';
                    width: 5px;
                    position: absolute;
                    background-color: white;
                    position: absolute;
                    height: 100vh;
                    min-height: -webkit-fill-available;
                    left: -5px;
                    top: 60px;
                    display:none;
                    @media screen and (min-width:768px){
                        display:inherit;
                        height:auto;
                    }
                    @media screen and (min-width:1024px){
                        height:100vh;
                        min-height:auto;
                    }
                }

                img{
                    background: transparent;
                }
            }

            &_side{
                position: absolute;
                right: 0;
                color: white;
                top: 45%;
                display:none;
                @media screen and (min-width:768px){
                    display:block;
                }
    
                p{
                    transform: rotate(270deg);
                    width:210px;
                }
    
                img{
                    position: absolute;
                    right: 60px;
                    top: 0;
                }
            }

            &_inner {
                padding:25px;
                width:100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @media screen and (min-width:580px){
                    padding:25px 75px;
                }
                @media screen and (min-width:768px){
                    align-self: flex-end;
                }
                @media screen and (min-width:1024px){
                    position:absolute;
                    width:90%;
                    bottom:0;
    
                }

                h1 {
                    font-family: "Helvetica Neue", "Inter", sans-serif;
                    font-size:50px;
                    font-weight:bold;
                    letter-spacing:-1.75px;
                    line-height:48px;
                    width:100%;
                    margin-bottom:-10px;
                    color:#fff;
                    @media screen and (min-width:1200px){
                        font-size:50px;
                        width:40%;
                        line-height:48px;
                        margin-bottom:34px;
                    }
                }

                h2 {
                    font-family: "Helvetica Neue", "Inter", sans-serif;
                    font-size:18px;
                    font-weight:bold;
                    letter-spacing:0.63px;
                    line-height:24px;
                    width:100%;
                    color:#fff;
                    margin-top:30px;
                    @media screen and (min-width:1200px){
                        width:42%;
                        font-size:18px;
                        line-height:24px;
                        margin-bottom:35px;
                        margin-top:0;
                    }
                }

                a{
                    color:#000;
                    @media screen and (min-width:768px){
                        color:#fff;
                    }
                }

                div div:first-child { 
                    width:300px;
                    @media screen and (min-width:1024px){
                        width:400px;
                        padding: 0 40px 0 0;
                    }
                }
            }
        }
    }

    figure.wp-block-image {
        vertical-align:unset;
    }

    .left-side {
        width:100%;
        height:100%;
        @media screen and (min-width:1024px){
            width:50%;
            display:flex;
            display:-webkit-flex;
        }

        img {
            width:100%;
            height: inherit;
            object-fit:cover;
            @media screen and (min-width:768px){
                height: 100vh;
                min-height: -webkit-fill-available;
                object-fit:cover;
            }
            @media screen and (min-width:1024px){
                height: 100vh;
                min-height:auto;
            }
        }

            a {
                display:none;
                @media screen and (min-width:768px){
                    display:block;
                    position:fixed;
                    top:1%;
                    left:2%;
                    background:rgba(33, 33, 33, 0.2);
                    padding-right:6px;
                    color:#fff;
                    font-size:16px;
                    font-weight:500;
                    width:170px;
                    height:48px;
                    padding:8px;
                    z-index:1;
                    &:hover {
                        background:#333;
                        cursor:pointer;
                    }
                }
            }

            .return-arrow {
                width: 14px;
                height: 14px;
                min-height: 14px;
                margin: 10px;
            }    
        }        
    }

    .right-side {
        width:100%;
        padding:20px;
        display:flex;
        display:-webkit-flex;
        flex-direction:column;
        vertical-align:bottom;
        height:auto;
        white-space: break-spaces;

        @media screen and (min-width:768px){
            height: 100vh;
            min-height:100%;
            max-height:-webkit-fill-available;
            justify-content:space-between;
        }
        @media screen and (min-width:1024px){
            width:50%;
            height: 100vh;
            min-height:100%;
            padding:50px;
        }

        .static-quote {
            font-size: 16px;
            font-weight:bold;
            line-height:20px;
            letter-spacing: -0.63px;
            color:#fff;
            margin-bottom:40px;
            @media screen and (min-width:768px){
                font-size: 21px;
                line-height:25px;
            }
            @media screen and (min-width:768px) and (max-height:800px){
                font-size: 17px;
                line-height:20px;
                margin-bottom:20px;
            }

            @media screen and (min-width:1500px){
                width: 65%;
                margin-top: 0px;
            }
        }

        h2 {
            font-family: "Helvetica Neue", "Inter", sans-serif;
            font-size: 25px;
            font-weight: bold;
            line-height: 28px;
            color:#fff;
            letter-spacing:-0.25px;
            display:inline;
            @media screen and (min-width:768px){
                font-size: 32px;
                line-height: 30px;
            }
            @media screen and (min-width:768px) and (max-height:800px){
                font-size: 32px;
                line-height: 30px;
            }
            @media screen and (min-width:1024px) and (max-height:800px){
                font-size: 37px;
                line-height: 30px;
            }
            @media screen and (min-width:1024px) and (min-height:801px){
                font-size: 40px;    
                line-height:43px;
            }
            @media screen and (min-width:1500px){
                font-size: 45px;
                line-height: 49px;
            }
        }

        .sub-right{
            position:relative;
            // top:14%;
            @media screen and (min-width:768px){
                // position:sticky;
            }

            .topQuote{
                display:block;
                margin-bottom:16px;
                @media screen and (min-width:768px){
                    margin-bottom:26px;
                    width:40px;
                }
                @media screen and (min-width:768px) and (max-height:800px){
                    margin-bottom:5px;
                    width:20px;
                }
                @media screen and (min-width:1024px) and (max-height:800px){
                    margin-bottom:10px;
                    width:30px;
                }

                @media screen and (min-width:1500px){
                    margin-bottom: 10px;
                    width: 40px;
                }
                
            }

            .bottomQuote{
                transform: rotate(180deg);
                width: 19px;
                vertical-align: 15px;
                margin-left:10px;
            }

            .auth-link {
                display:flex;
                display:-webkit-flex;
                align-items:center;
                justify-content:flex-start;
                margin-top:54px;
                @media screen and (min-width:768px) and (max-height:800px){
                    margin-top:40px
                }
                @media screen and (min-width:1024px) and (min-height:801px){
                    margin-top:84px;
                }
                @media screen and (min-width:1024px) {
                    margin-top:34px;
                }
                @media screen and (min-width:1500px){
                    margin-top:45px;
                }


                img {
                    max-width:136px;
                    margin-right:18px;
                    @media screen and (min-width:768px){
                        margin-right:26px;
                    }

                    &.author{
                        width:100px;
                        height:100px;
                        @media screen and (min-width:768px) and (max-height:800px){
                            width:100px;
                            height:100px;
                        }
                        @media screen and (min-width:768px) and (min-height:801px){
                            width:120px;
                            height:120px;
                        }
                    }
                }

                p {
                    font-family: "Helvetica Neue", "Inter", sans-serif;
                    font-size:18px;
                    font-weight:bold;
                    line-height:26px;
                    letter-spacing:-0.91px;
                    color:#fff;
                    margin-bottom:0px;
                    @media screen and (min-width:768px){
                        margin-bottom:10px;
                        font-size:26px;
                    }
                }
                a {
                    font-family: "Helvetica Neue", "Inter", sans-serif;
                    color:rgba(255, 251, 250, 0.5);
                    letter-spacing:-0.91px;
                    &:hover {
                        color:#fff;
                    }
                }
            }
        }
    }

    .extra {
        background:rgba(255, 251, 244, 1) !important;

        h2 {
            font-family: "Helvetica Neue", "Inter", sans-serif;
            color:rgba(33,33,33,1);
            font-weight:bold;
            font-size:35px;
            letter-spacing:-0.25px;
            line-height:36px;
            margin-bottom:15%;
            @media screen and (min-width:768px){
                font-size:50px;
                margin-bottom:15%;
            }
        }

        &_bundle {
            padding:10px 0;

            h3 {
                font-family: "Helvetica Neue", "Inter", sans-serif;
                font-size:22px;
                font-weight:bold;
                line-height:26px;
                @media screen and (min-width:768px){
                    font-size:26px;
                }
            }

            a {
                font-family: "Helvetica Neue", "Inter", sans-serif;
                display:flex;
                display:-webkit-flex;
                padding-bottom:20px;
                color:rgba(33,33,33, 0.5);
                font-size:18px;
                &:hover {
                    color:rgba(33,33,33,1);
                }
            }

            hr {
                border: 0.1px solid rgba(33,33,33, 0.1);
            }
        }
    }
`;
