
import styled from 'styled-components';

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  opacity:60%;
  margin-bottom:5px;
  margin-right:5px;
`;

export const WrapLinks = styled.ul`
  z-index: 10;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, auto [col-start]);

  li {
    letter-spacing: -0.28px;
  }
  a.active {
    border-bottom: 2px solid #000;
  }
  
  @media only screen and (max-width: 1279px) {
    display:none;
  }
`;

export const StyledMenu = styled.nav`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFBF4;
  transform: ${({ open }) => open ? 'translateY(100px)' : 'translateX(-100%)'};
  text-align: left;
  padding: 0.5rem 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:225px;
  transition: transform 0.3s ease-in-out;

  li {
    letter-spacing: -0.28px;
  }
  a.active {
    border-bottom: 2px solid #000;
  }

  a {
    font-size: 18px;
    margin-top: 18px;
    text-decoration: none;

    &:hover {
      color: #343078;
    }
  }
`

export const OpaqueDiv = styled.div` {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top:225px;
  left: 0;
  width:100%;
  height:100vh;
  min-height: -webkit-fill-available;
  transition: transform 0.3s ease-in-out;
  }
`

export const LogoDiv = styled.a` {
  position: absolute;
  top:-132px;
  left: 24px;
  width:93px;
  height:53px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateY(163px)' : 'translateX(-100%)'};
  cursor:pointer;
  }
`


export const StyledBurger = styled.button`
  position: absolute;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background:  ${props => props.blackImg ? "white" : "black"};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`
