import { useLocation } from 'react-router-dom';
import Navigation from 'components/Navigation'
import NavigationWhite from 'components/Navigation/nav-white.js'
import LogoComponentWhite from 'components/Logo/indexWhite.js';
import LogoComponent from 'components/Logo/index';
import{ HeaderWrap,  HeaderWrapLanding} from './_styles';

const Header = () => {
  const location = useLocation();

  if(location.pathname === '/'){
    return (
      <HeaderWrapLanding>
        <LogoComponentWhite/>
        <NavigationWhite/>
      </HeaderWrapLanding>
    )
  }else{
    return(
      <HeaderWrap>
      <LogoComponent />
      <Navigation />
    </HeaderWrap>
      )
  }
}

export default Header;
