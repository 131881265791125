import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  h1 {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    margin-top: 100px;
    font-weight: 700;
    font-size:100px;
    margin-bottom: 0.2em;
    @media only screen and (max-width: 500px) {
      margin-top: 30px;
      font-size:3em;
    }
    @media only screen and (min-width: 500px) and (max-width: 768px) {
      margin-top: 30px;
      font-size:4em;
    }
    @media only screen and (min-width: 768px) and (max-width: 1280px) {
      margin-top: 40px;
    }
  }
  h2 { font-weight: 600; }
  h3 { font-weight: 500; }
  p{ 
    font-weight: 500;
    font-family: "Helvetica Neue", "Inter", sans-serif;
    opacity:70%;
    font-size: 16px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
  }

  a:link, a:visited, a:hover, a:active  {
    color: inherit;
  }

  ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }

  ul li { display: inline; }

  body {
    margin: 0;
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-style: normal;
    background: #FFFBF4;
  }

  .buttonActive{
    border: 3px solid black;
    color:black;
    font-weight:bold;
  }

  .hide{
    overflow:hidden !important;
  }

  .scrollable{
    @media only screen and (min-width: 768px) {
      overflow-y:hidden !important;
    }
  }

  .touch #root {
    @media only screen and (min-width: 768px) {
      overflow-y: hidden;
      height:100%;
    }
    & > div{
      height:100%;
    }
  }
`;
