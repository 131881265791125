import { useEffect, useState } from 'react';

const { REACT_APP_ENDPOINT } = process.env;

const useFetchHook = ( url ) => {
  const [result, setResult] = useState(null)
  const [error, seterror] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController()
    const signal = { signale: controller.signal }
    async function getData() {
      setIsLoading(true)
      try {
        const response = await fetch(`${REACT_APP_ENDPOINT}${url}`, signal)
        const data = await response.json()
        setResult(data)
      } catch (error) {
        seterror(error.message)
      }
      setIsLoading(false)
    }
    getData()

    return () => controller.abort()
  }, [url])

  return [
    result,
    error,
    isLoading
  ]
}

export default useFetchHook;