import { Landing, ScreenMessage } from './_styles';
import { useState, useEffect } from 'react';
import useFetchHook from '../../hooks/useFetchHook';
import Quotes from '../../assets/img/quotes.svg';
import ArrowRight from "../../assets/img/arrow-right-up-line-white.svg";
import ArrowLeft from "../../assets/img/arrow-right-up-line-left.svg";
import lastImg from "../../assets/img/eye.jpg";
import ScrollRight from "../../assets/img/arrow-right.svg";
import ArrowRightBlack from '../../assets/img/arrow-right-up-line.svg';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ModalComp from './modal';


const Skroll = () => {
    /* to get post data */
    const url = '/wp/v2/posts?lxl_per_page=100';
    const [result, error] = useFetchHook(url);

    let firstCardId = 0;
    if (result && result.length > 0){
        if(result[0])
        firstCardId = 'firstSlide'
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {setIsOpen(true);}
    function closeModal() {setIsOpen(false);}

    const page = window.location.pathname === '/';
    const screen = window.innerWidth >= 768;
    let blackImg = true;
    screen && page ? blackImg = false : blackImg = true;

    
    
    useEffect(() => {
        const scriptTag = document.createElement('script');
        const scriptTagOne = document.createElement('script');
        
        if (modalIsOpen) {
            document.body.classList.add('hide');
        } else {
            document.body.classList.add('scrollable');

            scriptTag.src = "/js/scroll.js";
            scriptTag.async = true;
            scriptTagOne.src = "/js/scrolltrigger.js";
            scriptTagOne.async = true;

            document.body.appendChild(scriptTag);
            document.body.appendChild(scriptTagOne);
        }
        
        return () => {
            window.scrollConverter.deactivate();

            document.body.classList.remove('hide');
            document.body.classList.remove('scrollable');
        };
    }, [modalIsOpen]);

    const wording = [
        'This year, as we explored issues related to equity, diversity, and inclusivity (EDI) in healthcare, it became impossible to separate them from the individual stories they represented.',
        'The people we have spoken to as part of Health For All show that by entwining humanity and healthcare together more closely, more is possible.',
        'It is possible to reach more people, bring effective medicines to market, support more patients, and improve both commercial and clinical outcomes.',
        'This year we explore the human side of healthcare – so we ask ourselves – health for all? What would it really look like if no one was left behind…'
    ];
    
return (
    <>
        <ScreenMessage>
            <div className="rotate-phone"/>
            <h4>Please, rotate the device back into portrait mode to enjoy the experience</h4>
        </ScreenMessage>
        <Landing>
            {error && <p>Error: {error}</p>}
        <div className="viewport" style={{maxWidth:'100%'}}>   
            <div className="scroll">
                <div className="card">

                    <ModalComp openModal={openModal} closeModal={closeModal} modalIsOpen={modalIsOpen}/>

                    <div className="landing">
                        <div className="landing_video"/>
                        <Header blackImg={blackImg}/>
                        <div className="landing_side">
                            <a href={`#firstSlide`}>
                                <p>SCROLL AND EXPLORE</p>
                                <img className="scroll-right" src={ScrollRight} alt="scroll right arrow" />
                            </a>
                        </div>
                        <div className="landing_inner">
                            <h1>What if no one was left behind?</h1>
                            <h2>Stories matter. They bind people together, create a sense of connection, and engage our thoughts, 
                                our emotions, and imagination all at once. 
                            </h2>
                            <button className="watchVid" onClick={openModal} >Watch the video 
                                <img src={ArrowRight} alt="arrow" />
                            </button>
                            <Footer/>
                        </div>
                    </div>                
                </div>
                

                {result && result.slice(0,4).map((post, idx) =>
                <div key={post.id} className="card" id={firstCardId}>
                    <div className="left-side">
                        <a href="/"><img className="return-arrow" src={ArrowLeft} alt="arrow" /> Back to home</a>
                        <img src={post.acf.hero_image.url} alt={post.acf.hero_image.alt} /> 
                    </div>
                    <div className="right-side" style={{backgroundColor: post.acf.background_colour}}>
                        <p className="static-quote" key={post.id}>{wording[idx]}</p>
                        <div className="sub-right">
                            <img className="topQuote" src={Quotes} alt="quotation marks"/>
                            <h2>{post.acf.quote}</h2>
                            <img className="bottomQuote" src={Quotes} alt="quotation marks"/>
                            <div className="auth-link">
                                <img className="author" src={post.acf.author_image.url} alt={post.acf.author_image.alt} />
                                <div className="auth-link_sub">
                                    <p>{post.acf.author_by}</p>
                                    <a href={`/story/${post.slug}`}>Read the story <img src={ArrowRight} alt="arrow" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )} 
                
                <div className="card">
                    <div className="left-side">
                         <img src={lastImg} alt="eye" /> 
                    </div>
                    <div className="right-side extra">
                        <div className="sub-right">
                            <h2>Explore Health For All.</h2>
                            <div className="extra_bundle">
                                <h3>About Health For All.</h3>
                                <a href={`/about-us`}>Learn more <img src={ArrowRightBlack} alt="arrow" /></a>
                                <hr />
                            </div>
                            <div className="extra_bundle">
                                <h3>See all stories</h3>
                                <a href={`/stories`}>View stories <img src={ArrowRightBlack} alt="arrow" /></a>
                                <hr />
                            </div>
                            <div className="extra_bundle">
                                <h3>Read about the contributors</h3>
                                <a href={`/contributors`}>Read more <img src={ArrowRightBlack} alt="arrow" /></a>
                                <hr />
                            </div>
                            <div className="extra_bundle">
                                <h3>Resources</h3>
                                <a href={`/resources`}>View resources <img src={ArrowRightBlack} alt="arrow" /></a>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </Landing>
    </>
        
    ) 
}

export default Skroll;