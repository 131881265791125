import styled from 'styled-components';

export const AboutPost = styled.div`
    .wp-block-image img {
        max-width:100%;
        height: auto;
    }

    .about-block {
        max-width:766px;
        margin:0 auto 30px auto;
        h2 {
            margin:30px 0;
            line-height:1.3;
        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }

        &_black{
            background-color: #000;
            color:#fff;
            padding:30px;

            p {
                opacity:1 !important;
                color:#bbb;
                a {
                    color:#fff;
                }
                img {
                    vertical-align:top;
                }
            }
            figure {
                width: 152px;
            }
            h2 {
                color:#fff;
                opacity:1;
                font-size:22px;
                font-weight:bold;
            }
        }
    }
`;

