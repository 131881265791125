import styled from 'styled-components';

export const Logo = styled.img`
  width: 93px;
  height: 53px;
  background: #FFFBF4;
  @media only screen and (min-width:1280px) {
    margin-left: -16px;
    padding-bottom: 5px;
    padding-right: 18px;
  }
`;

export const StickyLogo = styled.div`

  .sticky {
    z-index: 0;
    width:1px;
    top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: first baseline;
    height: 100%;
    border-left: 5px solid transparent;
    @media only screen and (min-width:1280px){
      position: fixed;
      padding-left: 11px;
      border-left: 5px solid black;
      left:2%;
    }
    @media only screen and (min-width:1400px){left:4%;}
    @media only screen and (min-width:1450px){left:6%;}
    @media only screen and (min-width:1500px){left:8%;}
    @media only screen and (min-width:1550px){left:10%;}
    @media only screen and (min-width:1600px){left:12%;}
    @media only screen and (min-width:1650px){left:14%;}
    @media only screen and (min-width:1700px){left:14%;}
    @media only screen and (min-width:1750px){left:14.5%;}
    @media only screen and (min-width:1800px){left:15.5%;}
    @media only screen and (min-width:1850px){left:18%;}
  }
`;
