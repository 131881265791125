import Masonry from 'react-masonry-css'
import { MasonryComponents} from './_styles'
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFetchHook from 'hooks/useFetchHook';
import CardsStory from 'components/Cards/stories'

const HighlightsComponents = ({ postSlug }) => {
  const history = useHistory();
  const url = '/wp/v2/posts?lxl_per_page=100'
  const [result, error, isLoading] = useFetchHook(url);
  const [randomPost, setrandomPost] = useState([]);
  // const initialLimit = 2;
  // const [limit, setLimit] = useState(initialLimit);

  useEffect(() => {

    if (result) {
      const arr = result.filter(post => post.slug !== postSlug);

      const chooseRandom = (arr, num = 1) => {
         const res = [];
         for(let i = 0; i < num; ){
            const random = Math.floor(Math.random() * arr.length);
            if(res.indexOf(arr[random]) !== -1){
               continue;
            }
            res.push(arr[random]);
            i++;
         }
         return res;
      };
      return(setrandomPost(chooseRandom(arr, 2)));
    }
  }, [result, postSlug])

  // const loadMore = () => {
  //   setLimit(limit + 2);
  // };

  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const breakpointColumnsObj = {
    default: 2,
  };

  if (isLoading) return <h2>Loading...</h2>
  return (
    <MasonryComponents>
     <h1>Highlights</h1>
    { error && <p>Error: {error}</p> }
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      { randomPost &&
        randomPost.map(post =>
          <div
            key={post.id}
            onClick={() => {scrollTop();
              history.push(`/story/${post.slug}`, { from: `${post.slug}` })}
          }>
            <CardsStory
              id={post.id}
              title={post.title.rendered}
              quote={post.acf.quote}
              author={post.acf.author_by}
              image={post.acf.image_display.url}
            />
        </div>)
      }
    </Masonry>
      {/*<WrapLoadButton>*/}
      {/*  {result && !(limit >= result.length) && <Button type="primary" onClick={loadMore}>Load More</Button>}*/}
      {/*</WrapLoadButton>*/}
    </MasonryComponents>
  )
}

export default HighlightsComponents;
