import styled from 'styled-components';

export const Layout = styled.div`
  max-width: 1200px;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
`;


export const Content = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 270px);
`;

