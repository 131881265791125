import Header from 'components/Header'
import Footer from 'components/Footer'
import { Layout, Content } from './_styles'
import Helmet from "react-helmet";


export default function LayoutComponent({ children }) {
  return (
    <>
    <Helmet
        script={[{ 
          type: 'text/javascript', 
          innerHTML: '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src= "https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f); })(window,document,"script","dataLayer","GTM-PJRMQGW");'
        }]} 
      />
    <Layout>
      <Header/>
      <Content>{children}</Content>
      <Footer/>
    </Layout>
    <noscript><iframe title="Google Tag Manager" src="https://www.googletagmanager.com/ns.html?id=GTM-PJRMQGW" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript> 
    </>
  );
}
