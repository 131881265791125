import React from 'react';
import Modal from 'react-modal';
import Close from '../../assets/img/close.svg'

const customStyles = {
    background: 'rgba(0,0,0,0.7)',
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0,0,0,0.9)',
      width: '85%',
      height: '90vh',
    },
  };

const customButton = {
    background: 'none',
    border: 'none',
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
    float: 'right',
};

Modal.setAppElement('#root');

const ModalComp = ({ modalIsOpen, afterOpenModal, closeModal}) => {
  
  return (
    <div>
        <Modal 
        style={customStyles}
        isOpen={modalIsOpen} 
        onAfterOpen={afterOpenModal} 
        onRequestClose={closeModal} 
        >
            <button style={customButton} onClick={closeModal}><img src={Close} alt="close icon" /></button>  
            <iframe src="https://player.vimeo.com/video/561267932" 
            width="100%" 
            height="90%" 
            title="No one left behind"
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            allowfullscreen>
            </iframe>
        </Modal>
    </div>
  );
}

export default ModalComp;