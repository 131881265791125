import {withRouter, Redirect} from 'react-router';
import useFetchHook from 'hooks/useFetchHook';
import {
  ButtonWrap,
  Button,
  HeroWrap,
  HeroLeft,
  HeroRight,
  AuthorImage,
  AuthorName,
  AuthorDescription,
  SocialsWrap,
  SocialIcon,
  ShareWrap,
  Story
} from './_styles';
import Arrow from "../../assets/img/arrow-right-up-line.svg";
import TwitterIcon from 'assets/img/twitter.png'
import FacebookIcon from 'assets/img/facebook.png'
import LinkedInIcon from 'assets/img/linkedin.png'

const PageInfo = function PageInfo({match}) {
  const url = `/wp/v2/posts/?slug=${match.params.slug}`;
  const [result, error, isLoading] = useFetchHook(url);

  if (isLoading) return <h2>Loading...</h2>

  return (
    <>
      {error && <p>Error: {error}</p>}
      {result && result.length === 0 ?
        <Redirect to="/404"/>
        :
        result && result.map(post =>
          <div key={post.id}>
            <ButtonWrap><Button href="/stories"><img src={Arrow} alt="arrow"/>Back to stories</Button></ButtonWrap>
            <HeroWrap>
              <HeroLeft><img src={post.acf.image_display.url} alt={`${post.title.rendered}`}/></HeroLeft>
              <HeroRight style={{backgroundColor: post.acf.background_colour}}>
                <AuthorImage src={post.acf.author_image.url} alt={`${post.title.rendered}`}/>
                <AuthorName>{post.acf.author_by}</AuthorName>
                <AuthorDescription>{post.acf.description}</AuthorDescription>
                <ShareWrap>SHARE
                  <SocialsWrap>
                    <SocialIcon href={post.acf.twitter} target="_blank" rel="noopener noreferrer">
                      <img src={TwitterIcon} alt="twitter"/>
                    </SocialIcon>
                    <SocialIcon href={post.acf.facebook} target="_blank" rel="noopener noreferrer">
                      <img src={FacebookIcon} alt="facebook"/>
                    </SocialIcon>
                    <SocialIcon href={post.acf.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src={LinkedInIcon} alt="linkedin"/>
                    </SocialIcon>
                  </SocialsWrap>
                </ShareWrap>
              </HeroRight>
            </HeroWrap>
            <Story>
              {post.content.rendered &&
              <div dangerouslySetInnerHTML={{__html: post.content.rendered}}/>
              }
            </Story>
          </div>
        )
      }
    </>
  )
}

export default withRouter(PageInfo);
