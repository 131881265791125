import useFetchHook from 'hooks/useFetchHook';
import { AboutPost } from './_styles';

const AboutUs = () => {
  const url = '/wp/v2/pages/74'
  const [result, error, isLoading] = useFetchHook(url);

  if (isLoading) return <h2>Loading...</h2>

  return (
    <div>
      { error && <p>Error: {error}</p> }
      <h1>About.</h1>
      <AboutPost>
        {result &&  <div dangerouslySetInnerHTML={{ __html: result.content.rendered }} /> }
      </AboutPost>
    </div>
  )
}

export default AboutUs;
