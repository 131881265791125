import styled from 'styled-components';

export const FooterWrap = styled.div`
  display:flex;
  justify-content: space-between;
  width: 100%;
  margin-top:50px;
  margin-bottom:20px;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FooterWrapLanding = styled.div`
  display:none;
  @media only screen and (min-width: 768px) {
    display:flex;
    justify-content: space-between;
    width: 100%;
    margin-top:50px;
    margin-bottom:20px;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const LogoWrap = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
  flex-direction:column;
  @media only screen and (min-width: 1024px) {
    flex-direction:row;
    width:300px;
    align-items: center;
  }
  @media only screen and (max-width: 1280px) {
    padding:10px;
    padding-right: 20px;
  }
`;


export const FooterText = styled.p`
  opacity:70%!important;
  margin:0;
  font-size:14px!important;
  @media only screen and (max-width: 1280px) {
    margin:10px;
  }
`;

export const FooterTextWhite = styled.p`
  opacity:70%!important;
  margin:0;
  font-size:14px!important;
  color:white;
  @media only screen and (max-width: 1280px) {
    margin:10px;
  }
`;

export const FooterTextBlack = styled.p`
  opacity:70%!important;
  margin:0;
  font-size:14px!important;
  color:#000;
  @media only screen and (max-width: 1280px) {
    margin:10px;
  }
`;

export const HLGLogo = styled.img`
  width: 152px;
  height: 42px;
`;


export const VisitLink = styled.a`
  opacity:70%;
  font-size:14px!important;
  margin-top:20px;
  @media only screen and (min-width: 1024px) {
    margin:0;
  }
`;

export const PrivacyLink = styled.a`
  font-size:14px!important;
`;

export const SocialsWrap = styled.div`
  display:flex;
  justify-content: flex-end;
  width: 40%;
  @media only screen and (max-width: 1100px) {
    justify-content: flex-start;
    margin: 10px 5px;
`;

export const SocialIcon = styled.a`
  img {
    width: 32px;
    height: 32px;
    margin: 5px;
  }
`;


