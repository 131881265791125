
import { useState, useEffect } from 'react'
import useFetchHook from 'hooks/useFetchHook'
import { NavLink } from 'react-router-dom'
import {WrapLinks, Icon, StyledMenu, StyledBurger, OpaqueDiv, LogoDiv} from './_styles'
import StoriesIcon from 'assets/img/stories.svg'
import AboutIcon from 'assets/img/about.svg'
import ContributorsIcon from 'assets/img/contributors.svg'
import DownloadsIcon from 'assets/img/downloads.svg'
import StoriesIconWhite from 'assets/img/storiesWhite.svg'
import AboutIconWhite from 'assets/img/aboutWhite.svg'
import ContributorsIconWhite from 'assets/img/contributorsWhite.svg'
import DownloadsIconWhite from 'assets/img/downloadsWhite.svg'

const Navigation = ({blackImg}) => {
  const url = '/menus/v1/menus/2'
  const [result, error, isLoading] = useFetchHook(url);
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  
  const breakpoint = 1280;  

  const Burger = ({ open }) => {
    return (
      <StyledBurger blackImg open={open} onClick={toggle}>
        <div/>
        <div/>
        <div/>
      </StyledBurger>
    )
}

  const checkIcon = (key) =>{
    if (key===1){
      return blackImg ? <Icon src={StoriesIcon}/> : <Icon src={StoriesIconWhite}/> 
    } else if(key===2){
      return blackImg ? <Icon src={AboutIcon}/> : <Icon src={AboutIconWhite}/> 
    } else if(key===3){
      return blackImg ? <Icon src={ContributorsIcon}/> : <Icon src={ContributorsIconWhite}/> 
    } else if(key===4){
      return blackImg ? <Icon src={DownloadsIcon}/> : <Icon src={DownloadsIconWhite}/> 
    }
  }

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    open && open === true ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, [open]);

  const Menu = ({ open }) => {
    return (

      <StyledMenu open={open}>
        {result &&
        <>
          {result.items && result.items.map(item =>
            <NavLink onClick={toggle} activeClassName="active" exact to={`${item.slug ? `/${item.slug}` : '/stories'}`} key={item.menu_order}>
              {checkIcon(item.menu_order)}
              <b>{item.title}</b></NavLink>
          )}
          <OpaqueDiv onClick={toggle}/>
        </>
        }
      </StyledMenu>

    )
  }



  if (isLoading) return <h2>Loading...</h2>

  return (
    <>
      { error && <p>Error: {error}</p>}

      {width < breakpoint ?

        <>
          <NavLink to="/" onClick={toggle}><LogoDiv open={open}/></NavLink>
          <Burger open={open} />
          <Menu open={open}/>
        </>
        :
        <WrapLinks>
          {result &&
          <>
            {result.items && result.items.map(item =>
              <NavLink activeClassName="active" exact to={`${item.slug ? `/${item.slug}` : '/stories'}`} key={item.menu_order}>
                {checkIcon(item.menu_order)}
                <li><b>{item.title}</b></li></NavLink>
            )}
          </>
          }
        </WrapLinks>
      }


    </>
  )
}

export default Navigation;
