import { useState } from "react";
import useFetchHook from 'hooks/useFetchHook';
import { ImageCard, MasonryComponents, Button, FilterButton, Filter} from './_styles'
import Masonry from 'react-masonry-css'
import {Card} from "antd";
import DownloadIcon from "../../assets/img/download-2.svg";
import ExternalIcon from "../../assets/img/arrow-right-up-line.svg";
import {WrapLoadButton} from "../../pages/Stories/_styles";

const { Meta } = Card;


const Downloads = () => {
  const url = '/wp/v2/downloads?lxl_per_page=100'
  const [result, error, isLoading] = useFetchHook(url);
  const initialLimit = 6;
  const [limit, setLimit] = useState(initialLimit);

  const breakpointColumnsObj = {
    default: 3,
    1280: 2,
    768: 1
  };
  const [type, setCurrentType] = useState(null);

  const loadMore = () => {
    setLimit(limit + 6);
  };

  if (isLoading) return <h2>Loading...</h2>

  return (
    <>
    <h3>Show me:</h3>
    <Filter>
      <FilterButton className={ type === null ? "buttonActive" : "button" } onClick={() => setCurrentType(null)}>All</FilterButton>
      <FilterButton className={ type === 'PDF' ? "buttonActive" : "button" } onClick={() => { setCurrentType('PDF') }}>PDFs</FilterButton>
      <FilterButton className={ type === 'Audiobook' ? "buttonActive" : "button" } onClick={() => setCurrentType('Audiobook')}>Audiobooks</FilterButton>
      <FilterButton className={ type === 'Podcast' ? "buttonActive" : "button" } onClick={() => setCurrentType('Podcast')}>Podcasts</FilterButton>
    </Filter>
    <MasonryComponents>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        { error && <p>Error: {error}</p> }

        { result &&
        result.slice(0, limit).filter((item) => type ? item.acf.file_filter_type === type : true).map(item => <div key={item.id}>
            <a className="downloadCard" rel="noreferrer" target="_blank" href={ item.acf.file_type === 'download' ? item.acf.file.url : item.acf.external_link_url}>
              <div className="tag">{item.acf.file_filter_type}</div>
              <Card
                hoverable
                style={{ width: '100%'}}
                cover={<ImageCard src={item.acf.card_image} />}
              >
                <Meta title={item.title.rendered} description={item.acf.caption} />

                { item.acf.file_type === 'download' ? <Button><img src={DownloadIcon} alt=""/>{item.acf.button_text}</Button> : <Button><img src={ExternalIcon} alt=""/>{item.acf.button_text}</Button>}
              </Card>
              </a>
          </div>
        )
        }
      </Masonry>
      <WrapLoadButton>
        {result && !(limit >= result.length) && <Button type="primary" className="ant-btn-primary" onClick={loadMore}>Load more</Button>}
      </WrapLoadButton>
    </MasonryComponents>
    </>
  )
}

export default Downloads;
