import { Card } from 'antd';
import { ImageCard, Button, StoryCard } from './_styles';
import Arrow from "../../assets/img/arrow-right-up-line.svg";

const { Meta } = Card;

const CardsStory = ({ id, title, quote, author, image }) => {

  const regex = /'/g;
  const uni = `\u02BC`;
 
  return (
    <StoryCard key={id}>
      <Card
        style={{ width: '100%' }}
        cover={<ImageCard alt={title} src={image} />}
      >
        <Meta charSet="utf-8" title={author} description={<q>{quote.match(regex)? quote.replace(regex, uni) : quote}</q>} />
        <Button>Read more<img src={Arrow} alt="arrow"/></Button>
      </Card>
    </StoryCard>
  )
}

export default CardsStory;
