import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
      column-gap: 20px;
  column-gap: 20px;

  ant-card {
    background: transparent;
  }

  .ant-card-body {
    padding: 24px 0;
  }
  
  .ant-card-title {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 20px;
    opacity:1;
    @media only screen and (min-width: 1280px) {
      font-size: 26px;
      line-height: 26px;
    }
  }

  .ant-card-description {
    font-family: "Helvetica Neue", "Inter", sans-serif;
    font-weight: 100;
    font-size: 16px;
    line-height: 26px;
    color: rgba(0, 0, 0);
    opacity:1;
  }

  .ant-btn {
    padding:0;
    font-weight:bold;
    font-size: 16px;

    &-link:hover, &-link:focus {
      color:#000;
      text-decoration: underline !important;
    }
  }

  .ant-card-hoverable:hover {
    box-shadow: none;
  }

`

export const WrapCard = styled.div`
  display: grid;

  .cardInner {
    background: transparent;
    border: none
  }
`
export const Icon = styled.img`
  width: 24px;
  height: 24px;
  opacity:1;
  margin-bottom:0px;
`;

export const ImageCard = styled.img`
  object-fit: cover;
  height: 100%;
`;

export const WrapLoadButton = styled.div`
  display: flex;
  justify-content: center;
  .ant-btn-primary{
    color: white!important;
    background: black!important;
    border-color: black!important;
    padding:4px 10px;
    font-weight:500;
    line-height:0;
    font-size:16px;
    &:hover {
      cursor:pointer;
    }
  }
`;

export const ButtonLink = styled.span`
  padding: 0;
  font-weight: bold;
  font-size: 16px;
`;

export const Button = styled.div`
  display:flex;
  font-size:18px;
  font-weight: bold;
  cursor:pointer;
  img{
    padding-right: 5px;
  }
`;