
import Downloads from 'components/Downloads'

const DownloadsComponents = () => {

  return (
    <>
    <h1>Resources.</h1>
      {/*/!* Page description *!/*/}
      {/*<PageInfo />*/}

      {/* List of downloads */}
      <Downloads />
    </>
  )
}

export default DownloadsComponents;
