import { useEffect, useState, useRef } from "react";
import useFetchHook from 'hooks/useFetchHook';
import { Card, Button } from 'antd';
import { ImageCard, Wrapper, WrapCard, Icon, WrapLoadButton, ButtonLink} from './_styles';
import ArrowIcon from 'assets/img/arrow-right-up-line.svg';

const Contributors = () => {
  const url = '/wp/v2/contributors?lxl_per_page=100'
  const [result, error, isLoading] = useFetchHook(url);
  
  const inititalLimit = 6;
  const [limit, setLimit] = useState(inititalLimit);

  const loadMore = () => {
    setLimit(limit + 6);
  };

  const containerRef   = useRef({});

  const onResize = () => {
    if(containerRef && containerRef.current){
        
      const titles = containerRef.current.querySelectorAll(".cardHeight");

      // reset titles
      titles.forEach(title => {
        title.style.height = "";
      });

      const titleHeights = [];

      titles.forEach(title => {
        titleHeights.push(title.offsetHeight);
      });

      // set to highest
      if (titleHeights.length) {
        const titleHeight = Math.max(...titleHeights);

        titles.forEach(title => {
          title.style.height = `${titleHeight}px`;
        });
      }
        
    }
  }

  useEffect ( () => {
    if (containerRef && containerRef.current) {
      window.addEventListener("resize", onResize);

      try {
        // when all fonts are loaded
        document.fonts.ready.then(function () {
          onResize();
        });
      } catch (e) {
        // fonts not supported, leave titles out of line
      }
    }

    return () => window.removeEventListener("resize", onResize);
  }, [containerRef]);

  if (isLoading) return <h2>Loading...</h2>

  return (
    <>
      <h1>Contributors.</h1>
      <Wrapper ref={containerRef}>
      { error && <p>Error: {error}</p> }
        { result &&
          result.slice(0, limit).map(item => 
            <a rel="noreferrer" target="_blank" href={item.acf.web} >
              <WrapCard key={item.id} className="cardHeight">
                <Card 
                  hoverable
                  className="cardInner"
                  cover={<ImageCard  alt={`${item.acf.first_name} ${item.acf.second_name}`} src={item.acf.profile_picture.url} />}
                  >
                  <p class="ant-card-title">{item.acf.first_name} {item.acf.second_name}</p>
                  <p class="ant-card-description">{item.acf.description}</p>
                  {item.acf.web && <ButtonLink class="button-link">Find out more <Icon src={ArrowIcon}/></ButtonLink>}
                </Card>
              </WrapCard>
            </a>
          )
        }
      </Wrapper>
      <WrapLoadButton>
        {result && !(limit >= result.length) && <Button type="primary" className="ant-btn-primary" onClick={loadMore}>Load more</Button>}
        </WrapLoadButton>
    </>
  )
}

export default Contributors;
