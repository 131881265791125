import { useLocation } from 'react-router-dom';
import{ FooterWrap, LogoWrap, HLGLogo, VisitLink, SocialsWrap, SocialIcon, FooterText, FooterTextWhite, FooterTextBlack, PrivacyLink, FooterWrapLanding} from './_styles';
import HLG from 'assets/img/HLGLogo.svg'
import HLGwhite from 'assets/img/HLGLogo-white.svg'
import Arrow from 'assets/img/arrow-right-up-line.svg'
import ArrowRightWhite from "../../assets/img/arrow-right-up-line-white.svg";
import InstagramIcon from 'assets/img/instagram.png'
import TwitterIcon from 'assets/img/twitter.png'
import FacebookIcon from 'assets/img/facebook.png'
import LinkedInIcon from 'assets/img/linkedin.png'

const Footer = ({blackImg}) => {
  const location = useLocation();

  if(location.pathname === '/'){
    return (
      <FooterWrapLanding>
      <LogoWrap>
      {blackImg ? <HLGLogo src={HLG} alt="Havas Lynx"/> : <HLGLogo src={HLGwhite} alt="Havas Lynx"/>}
    <VisitLink href="https://havaslynx.com/" target="_blank">Visit website{blackImg ? <img src={Arrow} alt="arrow"/>:<img src={ArrowRightWhite} alt="arrow"/>}</VisitLink>
      </LogoWrap>
      {blackImg ?
      <FooterTextBlack>© 2024 Havas Lynx<br/>Part of the Havas Health &amp; You network<br/></FooterTextBlack> :
      <FooterTextWhite>© 2024 Havas Lynx<br/>Part of the Havas Health &amp; You network<br/></FooterTextWhite>
      }
      <SocialsWrap>
        <SocialIcon href="https://www.instagram.com/havaslynxeu/" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="instagram"/></SocialIcon>
        <SocialIcon href="https://twitter.com/HAVASLYNXEU" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="twitter"/></SocialIcon>
        <SocialIcon href="https://www.facebook.com/HavasLynxGroup/" target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="facebook"/></SocialIcon>
        <SocialIcon href="https://www.linkedin.com/company/havas-lynx" target="_blank" rel="noopener noreferrer"><img src={LinkedInIcon} alt="linkedin"/></SocialIcon>
      </SocialsWrap>
    </FooterWrapLanding>
    )
  }else{
    return(
      <FooterWrap>
      <LogoWrap>
      <HLGLogo src={HLG} alt="Havas Lynx"/>
      <VisitLink href="https://havaslynx.com/" target="_blank">Visit website<img src={Arrow} alt="arrow"/></VisitLink>
      </LogoWrap>
      <FooterText>© 2024 Havas Lynx<br/>Part of the Havas Health &amp; You network<br/><PrivacyLink href="https://havaslynx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy<img src={Arrow} alt="arrow"/></PrivacyLink></FooterText>
      <SocialsWrap>
        <SocialIcon href="https://www.instagram.com/havaslynxeu/" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="instagram"/></SocialIcon>
        <SocialIcon href="https://twitter.com/HAVASLYNXEU" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="twitter"/></SocialIcon>
        <SocialIcon href="https://www.facebook.com/HavasLynxGroup/" target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="facebook"/></SocialIcon>
        <SocialIcon href="https://www.linkedin.com/company/havas-lynx" target="_blank" rel="noopener noreferrer"><img src={LinkedInIcon} alt="linkedin"/></SocialIcon>
      </SocialsWrap>
    </FooterWrap>
      )
    }
}

export default Footer;
